import { Order, OrderStatus } from "@/interfaces/order";
import { ApiProvider, PaginatedResult } from "@/providers/api-provider";
import { ResourceProvider } from "@/interfaces/resource-provider";
import { Contact } from "@/interfaces/contact";
import { Service } from "@/interfaces/service";

class OrderApiProvider extends ApiProvider implements ResourceProvider {
  defaults =
    "warehouse,segment,recipient,customer.address,customer,carrier,carrier.address,carrier.owner,carrier.services,stocks.product,stocks.product.family,stocks.product.attributes,stocks.warehouse,owner,owner.segments,owner.carriers,owner.carriers.carrier.address";

  async exportAll(params: URLSearchParams, columnList: Array<string> = []) {
    params.append("output", "csv");

    // Bidouille pour mapper les colonnes du front sur les champs en back.
    if (columnList.indexOf("warehouse.name") != -1)
      columnList[columnList.indexOf("warehouse.name")] = "warehouse.code";

    await this.downloadFile(`/orders?${params}&columns=${columnList.toString()}`);
  }

  async getAll(params: URLSearchParams): Promise<PaginatedResult<Order>> {
    params.append("include", "owner,recipient,carrier,carrier.owner,warehouse,segment");
    const response = await this.get(`/orders?` + params);
    return new PaginatedResult<Order>(response, Order);
  }

  async getAllWithStatus(
    params: URLSearchParams,
    status: OrderStatus
  ): Promise<PaginatedResult<Order>> {
    params.append("include", "stocks");
    params.set("filter[statusCode]", status);
    const response = await this.get(`/orders?` + params);
    return new PaginatedResult<Order>(response, Order);
  }

  async getOrder(id: string): Promise<Order | null> {
    const response = await this.get(`/orders/${id}?include=${this.defaults}`);
    return response ? new Order(response.data) : null;
  }

  // Return carrier from order
  async getCarrier(id: string) {
    const response = await this.get(`/orders/${id}/carrier`);
    return response ? new Contact(response) : null;
  }

  // Return service from order
  async getService(id: string) {
    const response = await this.get(`/orders/${id}/service`);
    return response ? new Service(response) : null;
  }

  // Return PDF from order
  async getPdf(id: string) {
    const response = await this.get(`/orders/${id}/pdf`);
    return response ?? null;
  }

  async deleteOrder(order: Order): Promise<Order | null> {
    const response = await this.delete(`/orders/${order.id}`);
    return response;
  }

  async updateOrder(order: Order, msg: [] | null = null): Promise<Order | null> {
    const response = await this.put(
      `/orders/${order.id}?include=${this.defaults}`,
      order.encode(),
      msg
    );
    return response ? new Order(response.data) : null;
  }

  async createOrder(order: Order): Promise<Order | null> {
    const response = await this.post(`/orders?include=${this.defaults}`, order.encode());
    return response ? new Order(response.data) : null;
  }
}

export const orderApiProvider = new OrderApiProvider();
