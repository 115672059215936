var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{staticClass:"pa-0 ma-0"},[_c('v-tooltip',{staticClass:"pa-0 ma-0",attrs:{"bottom":"","color":"kDartessNightBlue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"elevation":"5","fab":"","color":"kDartessNightBlue75","small":"","title":_vm.$t('common.export_file')},on:{"click":_vm.onExportClick}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"kDartessWhite","size":"25"}},[_vm._v("mdi-file-excel")])],1)]}}])},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('p',{staticClass:"pa-0 ma-0 text-body-1"},[_vm._v(_vm._s(_vm.$t("common.export_file")))])])],1),_c('grid-preferences-component',{ref:"preferences",attrs:{"preference":_vm.preference}}),_c('v-tooltip',{staticClass:"pa-0 ma-0",attrs:{"bottom":"","color":"kDartessNightBlue"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"elevation":"5","fab":"","color":"kDartessNightBlue75","small":"","dark":""}},'v-btn',attrs,false),Object.assign({}, onMenu, onTooltip)),[_c('v-icon',{attrs:{"color":"kDartessWhite","size":"25"}},[_vm._v("mdi-land-rows-horizontal")])],1)]}}],null,true)},[_c('v-list',_vm._l(([20, 100, 1000, 5000]),function(number){return _c('v-list-item',{key:number,on:{"click":function($event){return _vm.onPageSizeChanged(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1)]}}])},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('p',{staticClass:"pa-0 ma-0 text-body-1"},[_vm._v(_vm._s(_vm.$t("common.changePageSize")))])])],1)],1),_c('confirm-modal',{ref:"confirmExportDownload",attrs:{"text":_vm.$t('export_csv.confirm_text'),"title":_vm.$t('export_csv.title'),"yesCallback":_vm.startExport,"event":"openExportModal"}}),_c('export-csv-snackbar',{ref:"exportSnackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }