import { PreferencesList } from "@/interfaces/preference";
import store from "../../stores/store";
import { AttributeGroupType, AttributeValues } from "@/interfaces/attributes/attribute";
import moment from "moment";
import GridListFilterComponent from "../../components/_shared/grid-components/grid-filters/grid-list-filter-component.vue";
import { Grid } from "./grid-helper";
import GridNumericFilterComponent from "../../components/_shared/grid-components/grid-filters/grid-numeric-filter-component.vue";
import GridDateFilterComponent from "../../components/_shared/grid-components/grid-filters/grid-date-filter-component.vue";
import { Stock } from "@/interfaces/stock";

export abstract class StockGrid extends Grid {
  $refs: {
    config: HTMLFormElement;
    stockGridFilter: HTMLFormElement;
  };

  defaultPreference = {
    slug: PreferencesList.stock,
    columns: [
      "product.code",
      "product.label",
      "product.family.family.uuid",
      "product.attributes.family",
      "quality",
      "batch",
      "ubd",
      "bbd",
      "warehouse.name",
    ],
  };

  getCommonStockGridField(): object[] {
    return [
      {
        headerName: this.$t("product.code"),
        field: "product.code",
        width: 150,
        minWidth: 150,
      },
      {
        headerName: this.$t("product.label"),
        field: "product.label",
        cellClass: "cell-align-left",
        width: 300,
        minWidth: 300,
      },
      {
        headerName: this.$t("stock.quantity"),
        field: "quantity",
        filterFramework: GridNumericFilterComponent,
        cellClass: "cell-align-right",
      },
      {
        headerName: this.$t("stock.available"),
        field: "available",
        filterFramework: GridNumericFilterComponent,
        cellClass: "cell-align-right",
      },
      {
        headerName: this.$t("product.attributes.conditionning"),
        field: "product.attributes.conditionning",
        cellClass: "cell-align-right",
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["attribute/getAttribute"](
            AttributeGroupType.alcohol,
            "conditionning"
          ).values.map((e: AttributeValues) => ({ key: e.code, value: e.label })),
        },
      },
      {
        headerName: this.$t("product.attributes.taxation"),
        field: "product.attributes.taxation",
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["attribute/getAttribute"](
            AttributeGroupType.alcohol,
            "taxation"
          ).values.map((e: AttributeValues) => ({ key: e.code, value: e.label })),
        },
      },
      {
        headerName: this.$t("warehouse.name"),
        field: "warehouse.name",
        flex: 1,
        cellClass: "cell-align-left",
        filterFramework: null,
      },
      {
        headerName: this.$t("family.name"),
        field: "product.family.family.uuid",
        cellClass: "cell-align-left",
        width: 200,
        minWidth: 200,
        valueFormatter: (params: any) =>
          params.data?.product.family.family.name ? params.data?.product.family.family.name : "-",
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["productFamily/getAllFamiliesFilterLabeled"],
        },
      },
      {
        headerName: this.$t("family.sub_name"),
        field: "product.family.uuid",
        cellClass: "cell-align-left",
        valueFormatter: (params: any) =>
          params.data?.product.family.name ? params.data?.product.family.name : "-",
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["productFamily/getAllSubFamiliesFilterLabeled"],
        },
      },
      {
        headerName: this.$t("product.attributes.family"),
        field: "product.attributes.family",
        valueFormatter: (params: any) =>
          params.data?.product.attributes.family ? params.data?.product.attributes.family : "-",
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["attribute/getAttribute"](
            AttributeGroupType.alcohol,
            "family"
          ).values.map((e: AttributeValues) => ({ key: e.code, value: `${e.code} (${e.label})` })),
        },
        width: 100,
        minWidth: 100,
        cellClass: "cell-align-center",
      },
      {
        headerName: this.$t("product.attributes.vintage"),
        field: "product.attributes.vintage",
        cellClass: "cell-align-center",
        valueFormatter: (params: any) =>
          isNaN(params.data?.product.attributes.vintage)
            ? "-"
            : params.data?.product.attributes.vintage,
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: () => {
            const years = [];
            const now = moment().year();
            for (let year = now; year >= now - 10; year--) {
              years.push({ key: year, value: year.toString() });
            }
            return years;
          },
        },
      },
      {
        headerName: this.$t("product.attributes.color"),
        field: "product.attributes.color",
        cellRendererFramework: "GridColorChipComponent",
        cellClass: "cell-align-center",
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: store.getters["attribute/getAttribute"](
            AttributeGroupType.alcohol,
            "color"
          ).values.map((e: AttributeValues) => ({ key: e.code, value: e.label })),
        },
      },
      {
        headerName: this.$t("product.attributes.capacity"),
        field: "product.attributes.capacity",
        cellClass: "cell-align-right",
        valueGetter: (params: any) => Number(params.data?.product.attributes.capacity),
        valueFormatter: (params: any) =>
          isNaN(params.data?.product.attributes.capacity)
            ? "-"
            : params.data?.product.attributes.capacity,
        filterFramework: GridNumericFilterComponent,
      },
      {
        headerName: this.$t("product.attributes.pcb"),
        field: "product.attributes.pcb",
        cellClass: "cell-align-right",
        valueGetter: (params: any) => Number(params.data?.product.attributes?.pcb),
        valueFormatter: (params: any) =>
          isNaN(params.data?.product.attributes.pcb) ? "-" : params.data?.product.attributes.pcb,
        filterFramework: GridNumericFilterComponent,
      },
      {
        headerName: this.$t("stock.quality"),
        field: "quality",
        width: 150,
        minWidth: 150,
        filterFramework: GridListFilterComponent,
        filterParams: {
          values: () => Stock.qualities.map((quality) => ({ key: quality, value: quality })),
        },
      },
      {
        headerName: this.$t("stock.batch"),
        field: "batch",
        width: 150,
        minWidth: 150,
      },
      {
        headerName: this.$t("stock.ubd"),
        field: "ubd",
        cellClass: "cell-align-center",
        filterFramework: GridDateFilterComponent,
        valueFormatter(params: any) {
          if (params.data && params.data.ubd) {
            return moment(params.data?.ubd).format("L");
          }
          return "-";
        },
      },
      {
        headerName: this.$t("stock.bbd"),
        field: "bbd",
        cellClass: "cell-align-center",
        filterFramework: GridDateFilterComponent,
        valueFormatter(params: any) {
          if (params.data && params.data.bbd) {
            return moment(params.data?.bbd).format("L");
          }
          return "-";
        },
      },
      {
        headerName: this.$t("warehouse.name"),
        field: "warehouse.name",
        flex: 1,
        cellClass: "cell-align-left",
        filterFramework: null,
      },
    ];
  }
}
