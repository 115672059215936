




































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import GridBoolComponent from "../_shared/grid-components/grid-cells/grid-bool-component.vue";
import { Grid } from "@/utils/grid/grid-helper";
import { Product } from "@/interfaces/product";
import GridListComponent from "../_shared/grid-components/grid-cells/grid-list-component.vue";
import GridTextBtnComponent from "../_shared/grid-components/grid-cells/grid-text-btn-component.vue";
import { PreferencesList } from "@/interfaces/preference";
import GridMovementIconComponent from "../_shared/grid-components/grid-stocks/grid-movement-icon-component.vue";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridDateFilterComponent from "../_shared/grid-components/grid-filters/grid-date-filter-component.vue";
import moment from "moment";
import GridRightIconsComponent from "../_shared/grid-components/grid-right-icons-component.vue";
import GridListFilterComponent from "../_shared/grid-components/grid-filters/grid-list-filter-component.vue";
import { Stock } from "@/interfaces/stock";
import { Segment } from "@/interfaces/segment";
import GridTextFilterComponent from "@/components/_shared/grid-components/grid-filters/grid-text-filter-component.vue";
import store from "@/stores/store";
import { UserRole } from "@/interfaces/user";

@Component({
  name: "stock-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    GridBoolComponent,
    GridListComponent,
    GridTextBtnComponent,
    GridRightIconsComponent,
    GridMovementIconComponent,
    CommonSearchComponent,
  },
})
export default class StockMovementGrid extends Grid {
  @Prop() rowData: Array<Product>;
  private superAdmin = store.getters["user/getUser"].role == UserRole.superadmin;

  async beforeMount() {
    super.beforeMount();

    // Specific superadmin columns.
    if (this.superAdmin) {
      this.defaultPreference.columns.unshift("stock.owner.code", "stock.owner.name");
      this.columns.push({
        headerName: this.$t("owner.code"),
        field: "stock.owner.code",
      } as any);
      this.columns.push({
        headerName: this.$t("owner.name"),
        field: "stock.owner.name",
      } as any);
    }
  }

  defaultPreference = {
    slug: PreferencesList.movementStock,
    columns: [
      "date",
      "reason",
      "order.reference",
      "order.recipient.displayName",
      "reception.reference",
      "reception.supplier.code",
      "stock.product.code",
      "stock.product.label",
      "quantity",
      "note",
      "direction",
      "warehouse.name",
      "stock.batch",
      "stock.ubd",
      "stock.bbd",
    ],
  };

  columns = [
    {
      headerName: this.$t("form.date"),
      field: "date",
      sort: "desc",
      filterFramework: GridDateFilterComponent,
      valueFormatter(params: any) {
        if (params.data && params.data.date) {
          return moment(params.data?.date).format("L");
        }
        return "-";
      },
    },
    { headerName: this.$t("stock-movement.statusCode"), field: "reasonCode" },
    { headerName: this.$t("stock-movement.reason"), field: "reason" },
    {
      headerName: this.$t("order.reference_title"),
      field: "order.reference",
      sortable: false,
      filterFramework: GridTextFilterComponent,
    },
    {
      headerName: this.$t("recipient.name"),
      field: "order.recipient.displayName",
      sortable: false,
      filterFramework: GridTextFilterComponent,
    },
    {
      headerName: this.$t("reception.reference"),
      field: "reception.reference",
      sortable: false,
      filterFramework: GridTextFilterComponent,
    },
    {
      headerName: this.$t("supplier.name"),
      field: "reception.supplier.code",
      sortable: false,
      filterFramework: GridTextFilterComponent,
      valueFormatter(params: any) {
        return params.data?.reception?.supplier?.code;
      },
    },
    {
      headerName: this.$t("product.code"),
      field: "stock.product.code",
      width: 100,
      minWidth: 100,
      cellRendererFramework: "GridTextBtnComponent",
      valueGetter(params: any) {
        return {
          text: params.data?.stock?.product.code,
          active: true,
          event: "open-product-detail-modal",
          data: "product",
          contextSearchValue: params.data?.stock?.product.code,
        };
      },
    },
    {
      headerName: this.$t("product.label"),
      field: "stock.product.label",
      width: 300,
      minWidth: 300,
      cellClass: "cell-align-left",
    },
    {
      headerName: this.$t("stock-movement.quantity"),
      field: "quantity",
      cellClass: "cell-align-right",
      width: 75,
      minWidth: 75,
    },
    { headerName: this.$t("stock-movement.note"), field: "note" },
    {
      headerName: "▼▲",
      field: "direction",
      width: 60,
      minWidth: 60,
      sortable: true,
      filterFramework: null,
      resizable: false,
      cellRendererFramework: "GridMovementIconComponent",
      valueGetter(params: any) {
        return params.data?.direction === "output";
      },
    },
    {
      headerName: this.$tc("form.segment", 1),
      field: "segment",
      filterFramework: GridListFilterComponent,
      valueFormatter: function (params: any) {
        return params.data?.segment?.code ?? "-";
      },
      filterParams: {
        values: () => [
          ...Segment.list.map((s) => ({ key: s.code, value: s.code })),
          { key: "NULL", value: this.$t("form.no_segment") },
        ],
      },
    },
    {
      headerName: this.$t("warehouse.name"),
      field: "warehouse.name",
      flex: 1,
      filterFramework: null,
    },
    {
      headerName: this.$t("stock.batch"),
      field: "stock.batch",
      width: 80,
      minWidth: 80,
      valueFormatter: (params: any) => {
        return params.data?.stock?.batch ?? "-";
      },
    },
    {
      headerName: this.$t("stock.ubd"),
      field: "stock.ubd",
      filterFramework: GridDateFilterComponent,
      valueFormatter(params: any) {
        if (params.data && params.data.stock.ubd) {
          return moment(params.data?.stock.ubd).format("L");
        }
        return "-";
      },
    },
    {
      headerName: this.$t("stock.bbd"),
      field: "stock.bbd",
      filterFramework: GridDateFilterComponent,
      valueFormatter(params: any) {
        if (params.data && params.data.stock.bdd) {
          return moment(params.data?.stock.bdd).format("L");
        }
        return "-";
      },
    },
    {
      headerName: this.$t("stock.quality"),
      field: "stock.quality",
      filterFramework: GridListFilterComponent,
      filterParams: {
        values: () => Stock.qualities.map((quality) => ({ key: quality, value: quality })),
      },
    },
    {
      pinned: "right",
      cellRendererFramework: "GridIconRowComponent",
      cellClass: "cell-align-company",
      maxWidth: 40,
      width: 40,
      minWidth: 40,
      filterFramework: null,
      suppressMovable: true,
      resizable: false,
      valueGetter(params: any) {
        return { id: params.data?.id, active: [true] };
      },
      cellRendererParams: {
        icons: [
          /* {icon: 'mdi-clipboard-flow', to: "/stock/movement"}, */
        ],
      },
    },
  ];
}
