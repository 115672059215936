import { ApiProvider, PaginatedResult } from "@/providers/api-provider";
import { Stock } from "@/interfaces/stock";
import { ResourceProvider } from "@/interfaces/resource-provider";

class StockApiProvider extends ApiProvider implements ResourceProvider {
  defaults = "warehouse,product,product.attributes,product.family, product.owner, segment";

  async exportAll(params: URLSearchParams, columnList: Array<string> = []) {
    params.append("output", "csv");

    // Bidouille pour mapper les colonnes du front sur les champs en back.
    if (columnList.indexOf("warehouse.name") != -1)
      columnList[columnList.indexOf("warehouse.name")] = "warehouse.code";

    await this.downloadFile(`/stocks?${params}&columns=${columnList.toString()}`);
  }

  async getOne(stockId: string): Promise<Stock> {
    const response = await this.get(`/stocks?include=${this.defaults}&filter[uuid],=,${stockId}`);
    return response ? response.data[0] : null;
  }

  async getAll(params: URLSearchParams): Promise<PaginatedResult<Stock>> {
    params.append("include", this.defaults);
    const response = await this.get(`/stocks?` + params);
    return new PaginatedResult<Stock>(response, Stock);
  }
}

export const stockApiProvider = new StockApiProvider();
