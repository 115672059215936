




































import { Component, Prop } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import GridIconRowComponent from "../_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import { Grid } from "@/utils/grid/grid-helper";
import { Product } from "@/interfaces/product";
import GridListComponent from "../_shared/grid-components/grid-cells/grid-list-component.vue";
import GridTextBtnComponent from "../_shared/grid-components/grid-cells/grid-text-btn-component.vue";
import { PreferencesList } from "@/interfaces/preference";
import store from "../../stores/store";
import GridListFilterComponent from "../_shared/grid-components/grid-filters/grid-list-filter-component.vue";
import { AttributeGroupType, AttributeValues } from "@/interfaces/attributes/attribute";
import CommonSearchComponent from "../_shared/searchs/common-search-component.vue";
import GridNumericFilterComponent from "../_shared/grid-components/grid-filters/grid-numeric-filter-component.vue";
import moment from "moment";
import GridDateFilterComponent from "../_shared/grid-components/grid-filters/grid-date-filter-component.vue";
import GridRightIconsComponent from "../_shared/grid-components/grid-right-icons-component.vue";
import GridColorChipComponent from "../_shared/grid-components/grid-cells/grid-color-chip-component.vue";
import { Stock } from "@/interfaces/stock";
import { Segment } from "@/interfaces/segment";
import { User, UserRole } from "@/interfaces/user";

@Component({
  name: "stock-grid",
  components: {
    AgGridVue,
    GridIconRowComponent,
    GridListComponent,
    GridTextBtnComponent,
    GridRightIconsComponent,
    CommonSearchComponent,
    GridColorChipComponent,
  },
})
export default class StockGrid extends Grid {
  @Prop() rowData: Array<Product>;

  async beforeMount() {
    let user = store.getters["user/getUser"] as User;
    let superAdmin = user.role == UserRole.superadmin;

    // Specific superadmin columns.
    if (superAdmin) {
      this.defaultPreference.columns.unshift("product.owner.code", "product.owner.name");
      this.columns.push({
        headerName: this.$t("owner.code"),
        field: "product.owner.code",
      } as any);
      this.columns.push({
        headerName: this.$t("owner.name"),
        field: "product.owner.name",
      } as any);
    }

    if (!user.company.isPooledActivity) {
      this.columns.push({
        headerName: this.$tc("form.segment", 1),
        field: "segment",
        filterFramework: GridListFilterComponent,
        valueFormatter: function (params: any) {
          return params.data?.segment?.code ?? "-";
        },
        filterParams: {
          values: () => [
            ...Segment.list.map((s) => ({ key: s.code, value: s.code })),
            { key: "NULL", value: this.$t("form.no_segment") },
          ],
        },
      } as any);
    }
    super.beforeMount();
  }

  defaultPreference = {
    slug: PreferencesList.stock,
    columns: [
      "product.code",
      "product.label",
      "quantity",
      "available",
      "storedAt",
      "product.attributes.family",
      "product.attributes.color",
      "product.attributes.vintage",
      "product.attributes.capacity",
      "warehouse.name",
      "batch",
      "ubd",
      "bbd",
    ],
  };

  columns = [
    {
      headerName: this.$t("owner.code"),
      field: "product.owner.code",
      cellClass: "cell-align-left",
    },
    {
      headerName: this.$t("owner.name"),
      field: "product.owner.name",
      cellClass: "cell-align-left",
    },
    {
      headerName: this.$t("product.code"),
      field: "product.code",
      width: 100,
      minWidth: 100,
      cellRendererFramework: "GridTextBtnComponent",
      valueGetter: function (params: any) {
        return {
          text: params.data?.product.code,
          active: true,
          event: "open-product-detail-modal",
          data: "product",
        };
      },
    },
    {
      headerName: this.$t("product.label"),
      field: "product.label",
      width: 300,
      minWidth: 300,
      cellClass: "cell-align-left",
    },
    {
      headerName: this.$t("stock.quantity"),
      field: "quantity",
      filterFramework: GridNumericFilterComponent,
      cellClass: "cell-align-right",
    },
    {
      headerName: this.$t("stock.available"),
      field: "available",
      filterFramework: GridNumericFilterComponent,
      cellClass: "cell-align-right",
    },
    {
      headerName: this.$t("stock.stored_at"),
      field: "storedAt",
      filterFramework: GridDateFilterComponent,
      valueFormatter: function (params: any) {
        if (params.value) {
          return moment(params.value).format("L");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: this.$t("product.attributes.family"),
      field: "product.attributes.family",
      sortable: false,
      valueFormatter: (params: any) =>
        params.data?.product.attributes.family == null
          ? "-"
          : params.data?.product.attributes.family,
      filterFramework: GridListFilterComponent,
      filterParams: {
        values: store.getters["attribute/getAttribute"](
          AttributeGroupType.alcohol,
          "family"
        ).values.map((e: AttributeValues) => {
          return { key: e.code, value: e.code + " (" + e.label + ")" };
        }),
      },
    },
    {
      headerName: this.$t("product.attributes.color"),
      field: "product.attributes.color",
      sortable: false,
      cellRendererFramework: "GridColorChipComponent",
      filterFramework: GridListFilterComponent,
      filterParams: {
        values: store.getters["attribute/getAttribute"](
          AttributeGroupType.alcohol,
          "color"
        ).values.map((e: AttributeValues) => {
          return { key: e.code, value: e.label };
        }),
      },
    },
    {
      headerName: this.$t("product.attributes.vintage"),
      field: "product.attributes.vintage",
      sortable: false,
      valueFormatter: (params: any) =>
        isNaN(params.data?.product.attributes.vintage)
          ? "-"
          : params.data?.product.attributes.vintage,
      filterFramework: GridNumericFilterComponent,
    },
    {
      headerName: this.$t("product.attributes.capacity"),
      field: "product.attributes.capacity",
      cellClass: "cell-align-right",
      width: 75,
      minWidth: 75,
      sortable: false,
      valueFormatter: (params: any) =>
        isNaN(params.data?.product.attributes.capacity)
          ? "-"
          : params.data?.product.attributes.capacity,
      filterFramework: GridNumericFilterComponent,
    },
    {
      headerName: this.$t("warehouse.name"),
      field: "warehouse.name",
      flex: 1,
      filterFramework: null,
    },
    {
      headerName: this.$t("stock.batch"),
      field: "batch",
      width: 80,
      minWidth: 80,
    },
    {
      headerName: this.$t("stock.ubd"),
      field: "ubd",
      filterFramework: GridDateFilterComponent,
      valueFormatter: function (params: any) {
        if (params.value) {
          return moment(params.value).format("L");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: this.$t("stock.bbd"),
      field: "bbd",
      filterFramework: GridDateFilterComponent,
      valueFormatter: function (params: any) {
        if (params.value) {
          return moment(params.value).format("L");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: this.$t("stock.quality"),
      field: "quality",
      filterFramework: GridListFilterComponent,
      filterParams: {
        values: () => Stock.qualities.map((quality) => ({ key: quality, value: quality })),
      },
    },
    {
      pinned: "right",
      cellRendererFramework: "GridIconRowComponent",
      cellClass: "cell-align-company",
      maxWidth: 40,
      width: 40,
      minWidth: 40,
      sortable: false,
      filterFramework: null,
      suppressMovable: true,
      resizable: false,
      valueGetter: function (params: any) {
        return { id: params.data?.id, active: [true] };
      },
      cellRendererParams: {
        icons: [
          /*{icon: 'mdi-swap-vertical', to: "/stock/movement"},*/
        ],
      },
    },
  ];
}
