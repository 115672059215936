
















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Preference } from "@/interfaces/preference";
import GridPreferencesComponent from "./grid-preferences-component.vue";
import ConfirmModal from "@/components/_shared/modals/confirm-modal.vue";
import ExportCsvSnackbar from "@/components/_shared/others/export-csv-snackbar.vue";

@Component({
  name: "grid-right-icons-component",
  components: { ExportCsvSnackbar, GridPreferencesComponent, ConfirmModal },
})
export default class GridRightIconsComponent extends Vue {
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() exportHandler: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() onPageSizeChanged: Function;
  @Prop() preference: Preference;
  @Prop() maxRows: number;

  private rowsNumber = 1;
  private disabled = true;

  $refs: {
    preferences: HTMLFormElement;
    exportSnackbar: HTMLFormElement;
  };

  mounted() {
    this.$on("rowsChanged", (val: number) => {
      this.rowsNumber = val;
    });
  }

  @Watch("rowsNumber")
  onRowsChange(val: number) {
    this.disabled = val <= this.maxRows;
  }

  onExportClick() {
    if (this.rowsNumber <= this.maxRows) {
      this.startExport();
    } else {
      this.$eventHub.$emit("openExportModal");
    }
  }

  startExport() {
    this.$refs.exportSnackbar.$emit("updateValue", true);
    this.$refs.exportSnackbar.$emit("rowsQty", this.rowsNumber);
    this.exportHandler();
  }
}
