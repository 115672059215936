import { Prop, Vue } from "vue-property-decorator";
import GridIconRowComponent from "../../components/_shared/grid-components/grid-cells/grid-icon-row-component.vue";
import GridBoolComponent from "../../components/_shared/grid-components/grid-cells/grid-bool-component.vue";
import { Preference } from "@/interfaces/preference";
import store from "../../stores/store";
import GridQuantityComponent from "../../components/_shared/grid-components/grid-products/grid-quantity-component.vue";
import GridColorChipComponent from "../../components/_shared/grid-components/grid-cells/grid-color-chip-component.vue";
import { getLocaleText } from "./grid-fr";
import GridTextFilterComponent from "../../components/_shared/grid-components/grid-filters/grid-text-filter-component.vue";
import { QueryBuilder } from "@/providers/filter";
import { DataSource } from "./datasource";
import { UserRole } from "@/interfaces/user";

require("../../assets/css/ag-grid/ag-theme-dartess.scss");

export abstract class Grid extends Vue {
  @Prop() datasource: DataSource;

  externalFilters = new QueryBuilder();

  preference: Preference = {} as Preference;
  defaultPreference: Preference = {} as Preference;

  columns: Array<object>;

  maxRowsToExport = 3000;

  isSuperAdmin = store.getters["user/getUser"].role == UserRole.superadmin;

  frameworkComponents: object = {
    gridIconComponent: GridIconRowComponent,
    gridBoolComponent: GridBoolComponent,
    GridQuantityComponent,
    gridColorChipComponent: GridColorChipComponent,
  };

  defaultColDef = {
    sortable: true,
    resizable: true,
    minWidth: 100,
    width: 100,
    filterFramework: GridTextFilterComponent,
    cellClass: "cell-align-center",
  };

  gridOptions: any = {
    getRowNodeId: (data: any) => data.id,
    rowHeight: 30,
    localeText: getLocaleText(),
    isExternalFilterPresent: this.externalFilterPresent.bind(this),
    doesExternalFilterPass: this.externalFilterPass.bind(this),
    suppressCellSelection: true,
    suppressMenuHide: true,
    suppressDragLeaveHidesColumns: true,
    blockLoadDebounceMillis: 500,
  };

  $refs: {
    config: HTMLFormElement;
  };

  externalFilterPresent() {
    return false;
  }

  externalFilterPass(node: any): boolean {
    return true;
  }

  onFilterChange = () => {
    this.gridOptions.api.onFilterChanged();
  };

  /**
   * Get the number of rows in the pagination bar.
   */
  onPaginationChanged = (data) => {
    const rowsNumbers = data.api.paginationProxy.getMasterRowCount();
    if (this.$refs.config) {
      this.$refs.config.$emit("rowsChanged", rowsNumbers);
    }
  };

  onPageSizeChanged = (value) => {
    this.gridOptions.api.paginationSetPageSize(Number(value));
  };

  exportData = () => {
    let columnList = this.gridOptions.columnApi.getDisplayedCenterColumns().map((col) => col.colId);
    columnList = columnList.filter((val) => val != "0");
    this.datasource.exportData(columnList);
  };

  /**
   * Export all data to CSV (disregarding columns displayed)
   */
  exportDataWithAllColumns = () => {
    this.datasource.exportData([]);
  };

  /*
    RD: Not yet used
    @Watch('externalFilters')
    onExternalFiltersChange(value: QueryBuilder, oldValue: QueryBuilder) {
        this.onFilterChange();
    }
    */

  // This method have to be write in all all child class.
  async initUserPreferences() {
    await store.dispatch("preference/fetchPreference", this.defaultPreference.slug);
    const result = await store.getters["preference/getPreferenceByName"](
      this.defaultPreference.slug
    );
    this.preference = result || this.defaultPreference;
  }

  /// This method generate the array of an ordered column from preferences.
  getColumnDefsFromPreferences() {
    const columnDefs: Array<any> = [...this.preference.columns];
    const startColumn: Array<object> = [];
    this.columns.forEach((column: any) => {
      const prefIndex = this.preference.columns.findIndex((pref: string) => pref == column.field);
      if (column.pinned == undefined) {
        if (prefIndex == -1) {
          column.hide = true;
          startColumn.push(column);
        } else {
          columnDefs.splice(prefIndex, 1, column);
        }
      } else {
        startColumn.push(column);
      }
    });
    return [...startColumn, ...columnDefs];
  }

  /**
   * If the grid has a datasource, set the grid options, and if the grid has an api, set the column
   * definitions and default filter value
   */
  async beforeMount() {
    if (this.datasource) {
      this.gridOptions.context = this;
      this.gridOptions.rowModelType = "infinite";
      this.gridOptions.cacheBlockSize = 1000;
      this.gridOptions.datasource = this.datasource;
      this.gridOptions.overlayLoadingTemplate =
        '<span class="pa-8"><img src="/img/loader.gif" height="100"></span>';
    }
    await this.initUserPreferences();
    if (this.gridOptions.api) {
      // TODO: why api object is null on second beforeMount call
      this.gridOptions.api.setColumnDefs(this.getColumnDefsFromPreferences());
      this.gridOptions.api.sizeColumnsToFit();
      this.setDefaultFilterValue();
    }
  }

  setDefaultFilterValue() {
    if (this.$route.params.grid_filters) {
      for (const [code, value] of Object.entries(this.$route.params.grid_filters)) {
        this.gridOptions.api.getFilterInstance(code).setModel({ filter: value });
      }
      this.onFilterChange();
    }
  }
}

export interface ColorChipInfo {
  text: string;
  textColor: string;
  color: string;
}
